import axios from '@axios'

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    fetchIncidents(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get('/apps/incident/incidents', { params: queryParams })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchIncident(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/apps/incident/incidents/${id}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    addIncident(ctx, incidentData) {
      return new Promise((resolve, reject) => {
        axios
          .post('/apps/incident/incidents', { incident: incidentData })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    editIncident(ctx, incidentData) {
      return new Promise((resolve, reject) => {
        axios
          .patch('/apps/incident/incidents', { incident: incidentData })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },    
  },
}
