<template>
  <b-sidebar
    id="add-new-incident-sidebar"
    :visible="isAddNewIncidentSidebarActive"
    bg-variant="white"
    sidebar-class="sidebar-lg"
    shadow
    backdrop
    no-header
    right
    @hidden="resetForm"
    @change="(val) => {$emit('update:is-add-new-incident-sidebar-active', val);showIncident(val)}"
  >
    <template #default="{ hide }">
      <!-- Header -->
      <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
        <h5 class="mb-0" v-if="!incidentData.id">
          Ajout d'un nouvel incident
        </h5>

        <h5 class="mb-0" v-if="incidentData.id">
          Modification d'un incident
        </h5>

        <feather-icon
          class="ml-1 cursor-pointer"
          icon="XIcon"
          size="16"
          @click="hide"
        />

      </div>


      <!-- BODY -->
      <validation-observer
        #default="{ handleSubmit }"
        ref="refFormObserver"
      >
        <!-- Form -->
        <b-form
          class="p-2"
          @submit.prevent="handleSubmit(onSubmit)"
          @reset.prevent="resetForm"
        >
        <span style="float:right" class="mb-1"> 
          <b-badge
            pill
            :variant="`light-${resolveUserStatusVariant(incidentData.status)}`"
            class="text-capitalize"
          >
            {{ incidentData.status }}
          </b-badge>
          </span>
          <!-- Full Name -->

            <b-form-group
              label="Dénomination"
              label-for="libelle"
              v-if="incidentData.id"
            >
            <span>{{ incidentData.libelle }}</span>

              <input type="hidden" name="id"   v-model="incidentData.id"/>            
            </b-form-group>

          <validation-provider
            #default="validationContext"
            name="libelle"
            rules="required"
            v-if="!incidentData.id"
          >
            <b-form-group
              label="Dénomination"
              label-for="libelle"
            >
              <b-form-input
                id="libelle"
                v-model="incidentData.libelle"
                autofocus
                :state="getValidationState(validationContext)"
                trim
                placeholder=""
              />        
              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>


            <b-form-group
              label="Description"
              label-for="description"
               v-if="incidentData.id"
            >
            <span>{{ incidentData.description }}</span>
            </b-form-group>


          <validation-provider
            #default="validationContext"
            name="description"
            rules="required"
            v-if="!incidentData.id"
          >
            <b-form-group
              label="Description"
              label-for="description"
            >
              <b-form-input
                id="description"
                v-model="incidentData.description"
                :state="getValidationState(validationContext)"
                trim
                placeholder=""
              />        
              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>


          <!-- Email -->
            <b-form-group
              label="Ouvrage concerné"
              label-for="ouvrage"
              v-if="incidentData.id"
            >
             <span>{{ incidentData.ouvrage }}</span>
            </b-form-group>

          <validation-provider
            #default="validationContext"
            name="ouvrage"
            rules="required"
            v-if="!incidentData.id"
          >
            <b-form-group
              label="Ouvrage concerné"
              label-for="ouvrage"
            >
              <b-form-input
                id="ouvrage"
                v-model="incidentData.ouvrage"
                autofocus
                :state="getValidationState(validationContext)"
                trim
                placeholder=""
              />        
              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>            

            <b-form-group
              label="Historique"
              label-for="historique"
            >
             <span v-for='value of incidentData.historique'>{{ value }}<br/></span>
            </b-form-group>             

            <span v-for='value of incidentData.documents' v-if="incidentData.id">
              <b-img :src=value width="300px" class="m-1"></b-img>
            </span>   


          <validation-provider
            #default="validationContext"
            name="documents"
            rules="required"
            v-if="!incidentData.id"
          >
            <b-form-group
              label="Photos"
              label-for="documents"
            >
              <b-form-file
              placeholder="Prendre une photo"
              multiple
              v-model="incidentData.documents"
              />      
              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- Form Actions -->
          <div class="d-flex mt-2">
            <b-button v-if="!incidentData.id"
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mr-2"
              type="submit"
            >
              Ajouter
            </b-button>
            <b-button  v-if="!incidentData.id"
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              type="button"
              variant="outline-secondary"
              @click="hide"
            >
              Annuler
            </b-button>

            <b-button v-if="incidentData.id && incidentData.status === 'A traiter'"
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mr-2"
              type="submit"
            >
              Cloturer l'incident
            </b-button>            
            <b-button v-if="incidentData.id "
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              type="button"
              variant="outline-secondary"
              @click="hide"
            >
              Fermer
            </b-button>
          </div>

        </b-form>
      </validation-observer>
    </template>
  </b-sidebar>
</template>

<script>
import {
  BSidebar, BForm, BFormGroup, BFormInput, BFormInvalidFeedback, BButton,BImg, BFormFile,BBadge
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { ref } from '@vue/composition-api'
import { required, alphaNum, email } from '@validations'
import formValidation from '@core/comp-functions/forms/form-validation'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import store from '@/store'
import useIncidentsList from '@/views/apps/incident/useIncidentsList'

export default {
  components: {
    BSidebar,
    BForm,
    BImg,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    BButton,
    vSelect,
    BFormFile,
    BBadge,

    // Form Validation
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  model: {
    prop: 'isAddNewIncidentSidebarActive',
    prop: 'composantsList',
    event: 'update:is-add-new-incident-sidebar-active',
  },
  props: {
    isAddNewIncidentSidebarActive: {
      type: Boolean,
      required: true,
    },
    inIncidentData: {
      type: Object,
      default: (() => {id:''}),
    },   
    composantsList: {
      type: Array,
      default: []
    }, 
  },

 methods: {
    loadIncident() {
      if (this.inIncidentData.id != '') {
        this.incidentData = JSON.parse(JSON.stringify(this.inIncidentData))
      } else {
        this.incidentData = this.blankIncidentData
      }

    },
    showIncident: function (val) { 

      if (val) {
        this.loadIncident()
      } else {
        this.incidentData = JSON.parse(JSON.stringify(this.blankIncidentData))
      }
    },
 },  
 
  data() {
    //this.desordreData.value = this.inDesordreData
    this.loadIncident()
 
    return {
      required,
      alphaNum,
      email,
      types:['Fissure','Altération','type 1','type 2'],
      iqoas:['1','2','2E','3','3U'],
    }
  },

created () {
    this.blankIncidentData = {
      id: '',
      libelle: '',
      description: '',
      ouvrage: '',
      status: 'A traiter',
      created: new Date().toLocaleDateString("fr-FR"),
      updated: new Date().toLocaleDateString("fr-FR"),
      author: 'John Doe',
      documents: [],
      historique: [],
    }
},

  setup(props, { emit }) {
    const blankIncidentData = {
      id: '',
      libelle: '',
      description: '',
      ouvrage: '',
      status: 'A traiter',
      created: new Date().toLocaleDateString("fr-FR"),
      updated: new Date().toLocaleDateString("fr-FR"),
      author: 'John Doe',
      documents: [],
      historique: [],
    }

    let incidentData = ref(JSON.parse(JSON.stringify(blankIncidentData)))

    if (props.inIncidentData.id != '') {
      incidentData.value = JSON.parse(JSON.stringify(blankIncidentData))
    }

    const resetincidentData = () => {
      incidentData.value = JSON.parse(JSON.stringify(blankIncidentData))
    }

    const onSubmit = () => {

    }
    const {
      resolveUserStatusVariant,


    } = useIncidentsList()

    const {
      refFormObserver,
      getValidationState,
      resetForm,
    } = formValidation(resetincidentData)

    return {
      incidentData,
      onSubmit,
      resolveUserStatusVariant,
      refFormObserver,
      getValidationState,
      resetForm,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';

#add-new-incident-sidebar {
  .vs__dropdown-menu {
    max-height: 200px !important;
  }
}
</style>
