<template> 

  <div>

    <incident-list-add-new
      :is-add-new-incident-sidebar-active.sync="isAddNewIncidentSidebarActive"
      @refetch-data="refetchData"
      :composantsList="arrayComposants"
        :inIncidentData="inIncidentData"      
    />

    <!-- Filters -->
    <incidents-list-filters
      :status-filter.sync="statusFilter"
      :status-options="statusOptions"
    />

    <!-- Table Container Card -->
    <b-card
      no-body
      class="mb-0"
    >

      <div class="m-2">

        <!-- Table Top -->
        <b-row>

          <!-- Per Page -->
          <b-col
            cols="12"
            md="6"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <label>Show</label>
            <v-select
              v-model="perPage"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="perPageOptions"
              :clearable="false"
              class="per-page-selector d-inline-block mx-50"
            />
            <label>entries</label>
          </b-col>

          <!-- Search -->
          <b-col
            cols="12"
            md="6"
          >
            <div class="d-flex align-items-center justify-content-end">
              <b-form-input
                v-model="searchQuery"
                class="d-inline-block mr-1"
                placeholder="Rechercher..."
              />
              <b-button
                variant="primary"
                @click="onAddIncident"
              >
                <span class="text-nowrap">Créer un incident</span>
              </b-button>
            </div>
          </b-col>
        </b-row>

      </div>

      <b-table
        ref="refUserListTable"
        class="position-relative"
        :items="fetchIncidents"
        responsive
        :fields="tableColumns"
        primary-key="id"
        :sort-by.sync="sortBy"
        show-empty
        empty-text="No matching records found"
        :sort-desc.sync="isSortDirDesc"
      >

        <!-- Column: User -->
        <template #cell(type)="data">
            <b-link
              :to="{ name: 'apps-users-view', params: { id: data.item.id } }"
              class="font-weight-bold d-block text-nowrap"
            >
              {{ data.item.type }}
            </b-link>
        </template>

        <template #cell(libelle)="data">
            <b-link
              @click="onIncidentEdit(data.item)"
              class="font-weight-bold d-block text-nowrap"
            >
              {{ data.item.libelle }}
            </b-link>
        </template>        

        <!-- Column: Role -->
        <template #cell(documents)="data">
    <span v-for='value of data.item.documents'>
      <b-img :src=value width="150px" class="m-1"></b-img>
    </span>           
        </template>

        <!-- Column: Status -->
        <template #cell(created)="data">
          <span class="align-text-top text-capitalize">     
            {{ data.item.created }}
          </span>
        </template>

        <!-- Column: Status -->
        <template #cell(status)="data">
          <b-badge
            pill
            :variant="`light-${resolveUserStatusVariant(data.item.status)}`"
            class="text-capitalize"
          >
            {{ data.item.status }}
          </b-badge>
        </template>



      </b-table>
      <div class="mx-2 mb-2">
        <b-row>

          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="text-muted">Showing {{ dataMeta.from }} to {{ dataMeta.to }} of {{ dataMeta.of }} entries</span>
          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >

            <b-pagination
              v-model="currentPage"
              :total-rows="totalIncidents"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>

          </b-col>

        </b-row>
      </div>
    </b-card>
  </div>
</template>
<script>
import {
  BCard, BRow, BCol, BFormInput, BButton, BTable, BMedia, BAvatar, BLink,
  BBadge, BDropdown, BDropdownItem, BPagination,BImg
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import store from '@/store'
import { ref, onUnmounted } from '@vue/composition-api'
import { avatarText } from '@core/utils/filter'
import IncidentsListFilters from '@/views/apps/incident/IncidentsListFilters.vue'
import useIncidentsList from '@/views/apps/incident/useIncidentsList'
import incidentStoreModule from '@/views/apps/incident/incidentStoreModule'
import IncidentListAddNew from '@/views/apps/incident/IncidentListAddNew.vue'

export default {
  components: {
    IncidentsListFilters,
    IncidentListAddNew,
    BImg,

    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,

    vSelect,
  },
  methods: {
    onAddIncident() {
       this.inIncidentData = {id:''}     
       this.isAddNewIncidentSidebarActive = true      
      //this.$router.push({ name: 'apps-maintenance-add' })
    },    

    onIncidentEdit: function (incident) { 
      console.log
       this.inIncidentData = incident     
       this.isAddNewIncidentSidebarActive = true

  },      

  },  
  data() {
    return {
      arrayComposants: ['GU156 - A142X - Corniche','GU156 - A143V - Chaussée et revêtement du tablier','GU156 - B51 - Mur de soutènement','GU156 - C1M59 - Bordure'], 
      inIncidentData:{}, 
    }
  },
  setup() {
    const USER_APP_STORE_MODULE_NAME = 'app-incident'

    // Register module
    if (!store.hasModule(USER_APP_STORE_MODULE_NAME)) store.registerModule(USER_APP_STORE_MODULE_NAME, incidentStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(USER_APP_STORE_MODULE_NAME)) store.unregisterModule(USER_APP_STORE_MODULE_NAME)
    })

    const isAddNewIncidentSidebarActive = ref(false)

    const statusOptions = [
      { label: 'A traiter', value: 'A traiter' },
      { label: 'Terminé', value: 'Terminé' },
    ]

    const {
      fetchIncidents,
      tableColumns,
      perPage,
      currentPage,
      totalIncidents,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refUserListTable,
      refetchData,

      // UI
      resolveUserRoleVariant,
      resolveUserRoleIcon,
      resolveUserStatusVariant,

      // Extra Filters
      statusFilter,
    } = useIncidentsList()

    return {

      // Sidebar
      isAddNewIncidentSidebarActive,

      fetchIncidents,
      tableColumns,
      perPage,
      currentPage,
      totalIncidents,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refUserListTable,
      refetchData,

      // Filter
      avatarText,

      // UI
      resolveUserRoleVariant,
      resolveUserRoleIcon,
      resolveUserStatusVariant,

      statusOptions,

      // Extra Filters
      statusFilter,
    }
  },
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
